<template>


  <template v-if="setup === false"> 
    <setupgame  @setupcontent="setup"/>
  </template>

<template v-if="setup === true">
  <template v-if="typeof startMessages == 'undefined' ||  startMessages == ''">
    <div id='overlayDiv' ></div>
    }
  </template>
  <messages :msg=this.info :msgCount=this.count />
</template>
</template>

<script>
  import messages from './components/messages.vue'
  import setupgame from './components/setup.vue'
  import axios from 'axios'

  export default {
    name: 'App',
    components: {
      messages,
      setupgame
    },
    data () {
     return {
      info: '',
      name:'',
      startsound: false,
      count: 1,
      license: "",
      boxid: "",
      setup: false,
      encryptionEnd: '',
      startMessages: ''
    }
  },
  async mounted () {

 window.setInterval( async() => {
     if (localStorage.getItem("license") === null && localStorage.getItem("boxid") === null ) {
        this.setup = false
     } else {
      this.setup = true
      this.license = localStorage.getItem('license'); 
       this.boxid = localStorage.getItem('boxid'); 

    var response =  await this.getNotifications()
    this.info = response.data.body
    console.log(response.data.body)
   if(typeof response.data.body != "undefined"){
     this.encryptionEnd = response.data.body.encryptionEnd
     this.startMessages = response.data.body.startMessages
    this.name = this.info.name
  }
    if (  typeof this.startMessages !== 'undefined' ){
      if (this.startsound === false){
        var audio = new Audio('./audio/start.mp3');
        this.startsound = true;
        audio.play();
      }
    } else {
      this.info = false;
    }
  }


  }, 1000)
   
},
methods: {
  setupcontent() {
    this.setup = true
  },
  getNotifications() {
    return axios
    .post(process.env.VUE_APP_ip+'/messagecheck', {"license": this.license, "boxid": this.boxid})

  },

}
}
</script>

<style>

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 10px;

  }

  #overlayDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:99;
    background-color: #000;
  }
</style>


