<template>

<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous">


    <div class="center">
   <p class="d-flex justify-content-center" v-bind:class="{ isred: nameError}"> Enter your license number and boxid to get started </p>

  <div class="container ">
    <template v-if="error != ''">
      <div class="row">
        <p>{{error}}</p>
    </div>
    </template>
    <div class="row">
        <input class="input form-control" placeholder="License Number" v-model="license" />
    </div>
    <div class="row">
        <input class="input form-control" placeholder="Box ID" v-model="boxid" />
    </div>
      <div class="row">
        <button class="btn btn-primary" type="submit" @click="GameSetup()">Go</button>
    </div>
  </div>
  </div>
 


</template>

<script>
 
  import {defineComponent} from 'vue' 

export default defineComponent ({
  name: 'setupApp',
  props: {
    msg: Object,
    msgCount: Number
  },
  data () {
     return {
      count: 1,
      mmessage1answer: 0,
    mmessage2answer: 0,
    mmessage3answer: 0,
    }
  },
   methods: {
    GameSetup() {
      localStorage.setItem('license', this.license); 
      localStorage.setItem('boxid', this.boxid); 
      this.$emit('setup', true)
    }
   }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
